<template>
  <div class="modal fade" tabindex="-10" id="modal_add-anagrafica-impianto">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            {{
              fromEnteAggregato
                ? "Aggiungi Impianto aggregato"
                : "Aggiungi Impianto"
            }}
          </h5>

          <!--begin::Close-->
          <div
            class="btn btn-icon btn-sm btn-active-secondary"
            style="border: 0; !important"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="bi bi-x-lg"> </i>
          </div>
          <!--end::Close-->
        </div>
        <div class="modal-body">
          <div class="card-body p-6">
            <AddImpianto
              :fromModal="true"
              :fromEnteAggregato="fromEnteAggregato"
              @refreshList="$emit('refreshList')"
            ></AddImpianto>
          </div>
        </div>

        <div class="modal-footer">
          <span
            type="button"
            class="me-3 badge bg-light-danger rounded text-danger fs-5"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            Chiudi
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import AddImpianto from "../../../views/impianti/AddImpianto.vue";

export default defineComponent({
  name: "add-modal-impianto",
  data() {
    return {};
  },

  components: {
    AddImpianto,
  },
  props: {
    fromEnteAggregato: {
      type: Boolean,
    },
  },
  emits: ["refreshList"],
});
</script>

<style scoped>
.modal-content {
  width: 170%;
}

.modal-body {
  padding: 0px;
}

@media (max-width: 767.98px) {
  .modal-dialog {
    max-width: 58%;
    margin: 2px;
  }
}

@media (min-width: 768px) {
  .modal-dialog {
    max-width: 46%;
    /* margin: 5.75rem auto; */
    margin-top: 5.75rem;
    margin-right: auto;
    margin-bottom: 5.75rem;
    margin-left: 7rem;
  }
}

@media (min-width: 1199.98px) {
  .modal-dialog {
    max-width: 40%;
    margin-top: 5.75rem;
    margin-right: auto;
    margin-bottom: 5.75rem;
    margin-left: 28rem;
  }
}
</style>
