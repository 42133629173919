<template>
  <div>
    <FilterAssegnaImpianto
      @getImpAssEntiAggregatiList="searchImpAssEntiAggregatiList"
      @resetFilters="resetFilters"
      :resetted="resetted"
    />
    <TableAssegnaImpianto
      @getImpAssEntiAggregatiList="getImpAssEntiAggregatiList"
      @resetFilters="resetFilters"
      :ente="ente"
    />
  </div>
</template>

<script>
import {
  defineComponent,
  onMounted,
  computed,
  getCurrentInstance,
  ref,
} from "vue";
import { useStore } from "vuex";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { trimInput } from "@/composables/trimInput";
import TableAssegnaImpianto from "@/components/components-fit/enti-aggregati/impianti/TableAssegnaImpianto.vue";
import FilterAssegnaImpianto from "@/components/components-fit/enti-aggregati/impianti/FiltriAssegnaImpianto.vue";

export default defineComponent({
  name: "assegna-impianto-ente",
  components: {
    TableAssegnaImpianto,
    FilterAssegnaImpianto,
  },
  props: {
    ente: {
      type: Object,
    },
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Assegnazione Impianti", [
        "Lista Ente",
        "Dettaglio Ente",
        "Impianti Ente",
      ]);
    });
    const store = useStore();

    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;

    /*  const stagione = computed(() => store.getters.stagioneSelected); */
    const rowsToSkip = computed(
      () => store.getters.rowsToSkipImpAssEntiAggregati
    );
    const fetchRows = computed(
      () => store.getters.fetchRowsImpAssEntiAggregati
    );
    const sortColumn = computed(
      () => store.getters.sortColumnImpAssEntiAggregati
    );
    const sortOrder = computed(
      () => store.getters.sortOrderImpAssEntiAggregati
    );

    const denominazione = computed(
      () => store.getters.denominazioneImpAssEntiAggregati
    );
    const id_provincia = computed(
      () => store.getters.provinciaImpAssEntiAggregati
    );
    const id_comune = computed(() => store.getters.comuneImpAssEntiAggregati);

    const getImpAssEntiAggregatiList = () => {
      store.dispatch("setStoreListData", {
        keys: {
          id_provincia: id_provincia.value,
          id_comune: id_comune.value,
          denominazione: trimInput(denominazione.value),
          rowstoskip: rowsToSkip.value,
          fetchrows: fetchRows.value,
          sortcolumn: sortColumn.value,
          sortorder: sortOrder.value,
        },
        apiLink: globalApi.AFFILIAZIONI_ENTI_AGGREGATI_IMPIANTI_SELEZIONE_LIST,
        itemName: "enti_aggregati_assegna_impianti_list",
      });
    };
    const loaded = computed(() =>
      store.getters.getStateFromName(
        "loadedenti_aggregati_assegna_impianti_list"
      )
    );
    const searchImpAssEntiAggregatiList = () => {
      if (!loaded.value) return;
      store.commit("setRowsToSkipImpAssEntiAggregati");
      getImpAssEntiAggregatiList();
    };

    const resetted = ref(false);

    const resetFilters = () => {
      store.commit("resetFiltersImpAssEntiAggregati");
      resetted.value = true;
      setTimeout(() => {
        resetted.value = false;
      }, 1000);
      getImpAssEntiAggregatiList();
    };
    getImpAssEntiAggregatiList();

    return {
      searchImpAssEntiAggregatiList,
      getImpAssEntiAggregatiList,
      resetFilters,
      resetted,
    };
  },
});
</script>
