<template>
  <div>
    <div class="row mt-5">
      <div class="col-sm-4">
        <div>
          <router-link
            :to="`/enti-aggregati/dettaglio-ente/impianti-ente/${id_ente}`"
            class="btn btn-light text-gray-700 fs-6 text-start"
          >
            <i class="bi bi-arrow-left fs-6 text-gray-700"></i> Lista impianti
            ente
          </router-link>
        </div>
      </div>
      <div class="col-sm-4">
        <!-- v-if="isEnabled('fnEnteImpiantiAddImpianto')" -->
        <div>
          <button
            class="btn btn-light text-gray-700 fs-6 text-start"
            data-bs-toggle="modal"
            data-bs-target="#modal_add-anagrafica-impianto"
          >
            <i class="bi bi-plus fs-6 text-gray-700"></i>aggiungi anagrafica
            impianto aggregato
          </button>
        </div>
      </div>
      <div class="col-sm-4">
        <div class="text-end mt-4" style="margin-right: 19px">
          Visualizzati
          <b v-if="record > 0">
            {{ rowsToSkip + fetchRows - (fetchRows - 1) }}
            -
            {{
              rowsToSkip + fetchRows > record ? record : rowsToSkip + fetchRows
            }}
          </b>
          <b v-else> 0 </b>
          impianti di <b>{{ record }}</b>
        </div>
      </div>
    </div>
    <br />
    <div class="mx-auto my-auto text-center" v-if="!loaded">
      <div class="spinner-border text-gray-600" role="status"></div>
      <span class="text-gray-600 ps-3 fs-2">Loading</span>
    </div>
    <div
      v-else-if="
        enti_aggregati_assegna_impianti_list.length === 0 &&
        loaded &&
        (status == 200 || status == 204)
      "
      class="mx-auto my-auto text-center card shadow pt-5 mt-5 mb-5 pb-5"
    >
      <!-- <i class="bi bi-exclamation-triangle fs-2 text-black pb-4"></i> -->
      <span class="text-gray-700 ps-3 fs-2">
        Attenzione! Nessun risultato.
      </span>
      <span
        type="button"
        data-bs-toggle=""
        data-bs-target=""
        aria-expanded="false"
        aria-controls=""
        class="text-gray-600 pt-4"
        @click.prevent="$emit('resetFilters')"
      >
        <i class="bi bi-arrow-clockwise fs-2 text-gray-800"> </i
      ></span>
    </div>
    <div
      v-else-if="status != 200 && status != 204"
      class="mx-auto my-auto text-center card shadow pt-5 mt-5 mb-5 pb-5"
    >
      <!-- <i class="bi bi-exclamation-triangle fs-2 text-black pb-4"></i> -->
      <span class="text-gray-700 ps-3 fs-2">
        Attenzione! Si è verificato un errore. Riprovare più tardi.
      </span>
      <span
        type="button"
        data-bs-toggle=""
        data-bs-target=""
        aria-expanded="false"
        aria-controls=""
        class="text-gray-600 pt-4"
      >
        <router-link v-if="isEnabled('fnLinkBachecaCompleta')" to="/bacheca">
          <i class="bi bi-house fs-2 text-gray-800"> </i
        ></router-link>
        <router-link v-else to="/bacheca-public">
          <i class="bi bi-house fs-2 text-gray-800"> </i></router-link
      ></span>
    </div>
    <div v-else>
      <Datatable
        :table-header="tableHeader"
        :table-data="enti_aggregati_assegna_impianti_list"
        :total="record"
        :current-page="currentPage"
        :rows-per-page="fetchRows"
        :sortLabel="sortColumn"
        :order="sortOrder"
        @sort="setSortOrderColumn"
        @items-per-page-change="setFetchRowsImpAssEntiAggregati"
        @current-change="setCurrentPageImpAssEntiAggregati"
      >
        <template v-slot:cell-denominazione="{ row: data }">
          <!-- <router-link
              :to="`/impianti/dettaglio-impianti/anagrafica-impianti/${data.id}`"
              >}</router-link> -->
          <div class="tab-long">
            {{ data.denominazione }}
          </div>
        </template>
        <template v-slot:cell-localita="{ row: data }">
          <div class="tab-long-xl">
            {{ data.localita }}
          </div>
        </template>
        <template v-slot:cell-db_provenienza="{ row: data }">
          {{ data.db_provenienza }}
        </template>
        <template v-slot:cell-options="{ row: data }">
          <div
            v-if="!readOnly"
            class="btn-group"
            @click="impiantiDettaglio(data.id, stagioneSelected, id_ente)"
          >
            <button
              class="btn btn-sm dropdown"
              type="button"
              aria-expanded="false"
              data-bs-toggle="modal"
              data-bs-target="#kt_modal_assegna_campo"
            >
              <i class="bi bi-clipboard-check text-dark fs-4"></i
              ><span class="fs-7">Assegna</span>
            </button>
          </div>
        </template>
      </Datatable>
    </div>
  </div>
  <AssegnazioneCampi
    :impianto="selectedImpianto"
    :ente="ente"
    :isLoadingDetail="isLoadingDetail"
    :fromEnteAggregato="true"
  />
  <ModalAddImpianto
    @refreshList="$emit('getImpAssEntiAggregatiList')"
    :fromEnteAggregato="true"
  />
</template>

<script>
import Datatable from "../../../kt-datatable/KTDatatable.vue";
import { useStore } from "vuex";
import { ref, computed } from "vue";
import AssegnazioneCampi from "@/components/components-fit/enti-aggregati/impianti/AssegnazioneCampi.vue";
import ModalAddImpianto from "../../impianti/ModalAddImpianto.vue";
import { viewEditImpianto } from "@/requests/addImpianto";
import { useRoute } from "vue-router";
import { alertFailed } from "@/composables/swAlert";
import isEnabled from "@/composables/isEnabled.js";

export default {
  name: "TableAssegnaImpianto",
  components: {
    Datatable,
    AssegnazioneCampi,
    ModalAddImpianto,
  },
  emits: ["getImpAssEntiAggregatiList", "resetFilters"],
  props: {
    ente: {
      type: Object,
    },
  },
  setup(props, { emit }) {
    const route = useRoute(); // prende id
    const id_ente = ref(route.params.id);
    const stagioneSelected = computed(() => store.getters.stagioneSelected);

    const store = useStore();

    const setFetchRowsImpAssEntiAggregati = (e) => {
      store.commit("setFetchRowsImpAssEntiAggregati", e);
      emit("getImpAssEntiAggregatiList");
    };
    const setCurrentPageImpAssEntiAggregati = (page) => {
      store.commit("setCurrentPageImpAssEntiAggregati", page);
      emit("getImpAssEntiAggregatiList");
    };
    const setSortOrderColumn = ({ columnName, order }) => {
      store.commit("setSortColumnImpAssEntiAggregati", columnName);
      store.commit("setSortOrderImpAssEntiAggregati", order);
      emit("getImpAssEntiAggregatiList");
    };

    const tableHeader = ref([
      {
        name: "Denominazione",
        key: "denominazione",
      },
      {
        name: "Località",
        key: "localita",
        sortable: false,
      },
      {
        name: "Provenienza Dato",
        key: "db_provenienza",
      },
      {
        key: "options",
        sortable: false,
      },
    ]);

    const isLoadingDetail = ref(false);

    const selectedImpianto = ref({});
    const impiantiDettaglio = (id_Impianto, anno, id_Societa) => {
      selectedImpianto.value = {};
      isLoadingDetail.value = true;
      viewEditImpianto(id_Impianto, anno, id_Societa).then((res) => {
        if (res.length > 0) {
          selectedImpianto.value = res[0];
        } else {
          alertFailed(
            "Attenzione! Si è verificato un errore. Riprovare più tardi",
            "kt_modal_assegna_campo"
          );
        }
        isLoadingDetail.value = false;
      });
    };

    return {
      id_ente,
      stagioneSelected,
      enti_aggregati_assegna_impianti_list: computed(() =>
        store.getters.getStateFromName(
          "resultsenti_aggregati_assegna_impianti_list"
        )
      ),
      loaded: computed(() =>
        store.getters.getStateFromName(
          "loadedenti_aggregati_assegna_impianti_list"
        )
      ),
      record: computed(() =>
        store.getters.getStateFromName(
          "recordenti_aggregati_assegna_impianti_list"
        )
      ),
      status: computed(() =>
        store.getters.getStateFromName(
          "statusenti_aggregati_assegna_impianti_list"
        )
      ),

      currentPage: computed(() => store.getters.currentPageImpAssEntiAggregati),
      rowsToSkip: computed(() => store.getters.rowsToSkipImpAssEntiAggregati),
      fetchRows: computed(() => store.getters.fetchRowsImpAssEntiAggregati),
      sortColumn: computed(() => store.getters.sortColumnImpAssEntiAggregati),
      sortOrder: computed(() => store.getters.sortOrderImpAssEntiAggregati),
      setFetchRowsImpAssEntiAggregati,
      setCurrentPageImpAssEntiAggregati,
      setSortOrderColumn,
      tableHeader,
      impiantiDettaglio,
      selectedImpianto,
      isLoadingDetail,
      readOnly: computed(() => store.getters.readOnlySelectedRoute),
      isEnabled,
    };
  },
};
</script>

<style></style>
